import { getBlockTitle, uuidToId } from '@simpledotink/notion-utils'
import slugify from 'slugify'

/**
 *
 * @param pageId
 * @param recordMap
 * @param param2
 * @returns
 */
export const getCanonicalPageId = (
  pageId: string,
  recordMap: any,
  { uuid = true }: { uuid?: boolean } = {},
  siteMaps: any,
  site?:any,
): string | null =>  {
  if (!pageId || !recordMap) return null

  const page = site.pages?.find((page) => page.pageId === pageId);
  const slug: string = page ? page.slug : '';
  const id = uuidToId(pageId)
  const block: any = recordMap.block[pageId]?.value

  if (block) {
    let title = slugify(getBlockTitle(block, recordMap), {
      lower: true,
      remove: /[/*+~.()'"!:@?-]/g
    })
      const restrictedPages = ['5824cccc-3cc4-48d6-a8ea-02bb809c3ae7', '1170f3e1-03ae-4572-bc72-d17f517d34e6', '0670a062-ffc2-4fca-a1d9-4d75102f88ad', '8fd3b830-be7e-4b4c-817d-0e4fcf2a5279']
      if (site.siteId === 'cfa09a74-cc2d-4cda-be9f-dc32a3eac423' && restrictedPages.includes(pageId)) {
          title = title.replace(/#/g, '');
      }
    if (title) {
      if(site.siteId === "5feffab2-e6d9-40e0-8380-b98bb0ff92cc"){
        return `${slug}`
      }
     else if (uuid || !siteMaps[title]) {
        return `${title}-${id}`
      } else if (siteMaps[title]) {
        return title
      } else {
        return `${title}-${id}`
      }
    }
  }

  return id
}
